<template>
  <div class="match">
    <div class="bind" style="margin: 10px 15px 10px">
      <p class="p0">请选择</p>
      <span class="p1">您的身份</span>
      <div class="card1" @click="alertDialog(1)">
        <span>我是家长</span>
        <van-icon class="icon1" name="user-o" size="60" color="#FFFFFF" />
      </div>
      <div class="card2" @click="alertDialog(2)">
        <span>我是老师</span>
        <van-icon class="icon2" name="friends-o" size="60" color="#FFFFFF" />
      </div>
    </div>
    <van-dialog
      v-model:show="show"
      title="用户登录"
      show-cancel-button
      :before-close="beforeClose"
    >
      <form>
        <van-cell-group inset>
          <van-field
            v-model="userNO"
            maxlength="30"
            :rules="rules.uname"
            clearable
            label="用户账号"
            placeholder="请输入用户账号"
          />
          <van-field
            v-model="pwd"
            maxlength="30"
            label="密码"
            :rules="[{ required: true }]"
            placeholder="请输入密码"
            type="password"
          />
        </van-cell-group>
      </form>
    </van-dialog>
    <div class="footer">
      <div class="card3">
        <div class="icon">
          <van-icon name="info-o" size="16" color="#FD962D" />
        </div>
        <div class="content">
          <span class="text">1.如忘记登录密码请联系学校老师重置</span>
          <span class="text">2.登录后如需切换用户请在用户中心先解除绑定</span>
        </div>
      </div>
    </div>
    <!-- <van-loading class="loading" type="spinner" size="60px" /> -->
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { Toast } from "vant";
import { useRoute, useRouter } from "vue-router";
import api from "../service/api";
import { ConfigProvider } from "vant";
import { getStorage, setStorage } from "../common/js/storeUtil";
// @ts-ignore
import { AESencrypt } from "../common/js/encryptUtils";
import { isLogin } from "../common/js/isloginUtil";

export default {
  components: {
    [ConfigProvider.name]: ConfigProvider,
  },
  setup() {
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const router = useRouter(); //路由传值
    const route = useRoute();
    const show = ref(false);
    const userNO = ref("");
    const pwd = ref("");
    const openID = ref("");
    const child = ref();
    const listSize = ref(1);
    const uType = ref(1);
    const openId = ref("");
    const rules = {
      uname: [
        {
          pattern: /^[a-zA-Z0-9]{6,30}$/,
          message: "账号只能是6-30位数字或字母",
          trigger: "onBlur",
        },
      ],
    };

    const themeVars = {
      cellTextColor: "#ffffff",
      cellBackgroundColor: "#1989fa",
      cellLabelColor: "#ffffff",
      cellRightIconColor: "#ffffff",
    };
    // let store = useStore();
    const beforeClose = (action) => {
      if (action === "confirm") {
        userLogin();
      } else {
        show.value = false;
      }
    };

    const safeName = (str) => {
      if (str) {
        return str.replace(str.substring(0, 1), "*");
      } else {
        return "";
      }
    };

    onMounted(() => {
      /* eslint-disable */
      // setTitle("用户身份");
      if(getQuery("userId")){
        openId.value =  getQuery("userId");
        setStorage("open_id",openId.value);
      }
      if (isLogin()) {
        if(getStorage("open_id")){
          loginWithOpenId(getStorage("userNo"), getStorage("pwd"),getStorage("open_id"));
        }else{
          login(getStorage("userNo"), getStorage("pwd"));
        }
      }
     
      //  Toast(window.location.href);
    });
    function getQuery(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    }

    const onLoad = () => {};

    //账号中间加*号
    const mobileStr = (str) => {
      if (str) {
        return str.substring(0, 3) + "****" + str.substring(7, str.length);
      } else {
        return "";
      }
    };

    function validateInput(inputValue) {
      // 只能包含字母和数字，长度在6到30位之间
      var pattern = /^[a-zA-Z0-9]{6,30}$/;
      return pattern.test(inputValue);
    }

    function userLogin() {
      if (!validateInput(userNO.value)) {
        Toast("账号只能是6-30位数字或字母");
        return;
      }

      login(userNO.value, pwd.value);
    }

    //弹出添加对话框
    const alertDialog = (type) => {
      uType.value = type;
      setStorage("uType", uType.value);
      if (list.value.length >= 2) {
        Toast("已达到添加上限");
        return;
      } else {
        show.value = true;
      }
    };

    function loginWithOpenId(userNo, pwd,openID) {
      api
        .loginWithOpenId({
          openID: openID,
          user_no: userNo,
          password: pwd,
          type: getStorage("uType"),
        })
        .then((res) => {
          if (res.data.code == 1001) {
            let result = res.data.result;
            setStorage("pwd", pwd);
            setStorage("sid", result.sid);
            setStorage("userName", result.name);
            // setStorage("phone", result.phone);
            setStorage("userNo", result.user_no);
            setStorage("user_id", result.user_id);

            if (result.type == 1) {
              //学生
              let studentList = result.students;
              setStorage("studentId");
              setStorage("students", studentList);
              setStorage("student", studentList[0]);
              toPayment();
            } else if (result.type == 2) {
              //老师
              setStorage("schoolName", result.school_name);
              let classList = result.classList;
              setStorage("classes", classList);
              console.log("classes", classList);
              if (classList.length == 1) {
                setStorage("className", classList[0].class_name);
              }
              toTeacherHome();
            } else {
              Toast("获取数据失败");
            }
          } else {
            // @ts-ignore
            Toast(res.data.message);
            show.value = false;
          }
        })
        .catch((err) => {
          show.value = false;
          Toast("error: " + err.message);
        });
    }

    function login(userNo, pwd) {
      api
        .login({
          user_no: userNo,
          password: pwd,
          type: getStorage("uType"),
        })
        .then((res) => {
          if (res.data.code == 1001) {
            let result = res.data.result;
            setStorage("pwd", pwd);
            setStorage("sid", result.sid);
            setStorage("userName", result.name);
            // setStorage("phone", result.phone);
            setStorage("userNo", result.user_no);
            setStorage("user_id", result.user_id);

            if (result.type == 1) {
              //学生
              let studentList = result.students;
              setStorage("studentId");
              setStorage("students", studentList);
              setStorage("student", studentList[0]);
              toPayment();
            } else if (result.type == 2) {
              //老师
              setStorage("schoolName", result.school_name);
              let classList = result.classList;
              setStorage("classes", classList);
              console.log("classes", classList);
              if (classList.length == 1) {
                setStorage("className", classList[0].class_name);
              }
              toTeacherHome();
            } else {
              Toast("获取数据失败");
            }
          } else {
            // @ts-ignore
            Toast(res.data.message);
            show.value = false;
          }
        })
        .catch((err) => {
          show.value = false;
          Toast("error: " + err.message);
        });
    }

    //跳转到学生缴费类别页面
    function toPayment() {
      router.push({
        path: "/payment",
      });
    }
    //跳转到缴费类别页面
    function toTeacherHome() {
      router.push({
        path: "/teacherHome",
      });
    }

    return {
      list,
      onLoad,
      loading,
      finished,
      toPayment,
      alertDialog,
      show,
      beforeClose,
      userNO,
      pwd,
      child,
      openID,
      mobileStr,
      safeName,
      themeVars,
      listSize,
      rules,
      uType,
    };
  },
};
</script>

<style lang="less">
.match {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #f3f8fe, #edf3fa);

  .p1 {
    font-size: 26px;
    line-height: 0.5;
  }

  .card1 {
    background-color: #92c2fe;
    background-image: linear-gradient(to bottom right, #5da7fd, #92c2fe);
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: 70px;
    margin-top: 20px;
    position: relative;

    span {
      color: white;
      font-size: 24px;
    }
    .icon1 {
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  }
  .card2 {
    background-color: #92c2fe;
    background-image: linear-gradient(to bottom right, #5da7fd, #92c2fe);
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: 70px;
    margin-top: 20px;
    position: relative;

    span {
      color: white;
      font-size: 24px;
    }
    .icon2 {
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  }

  .footer {
    background-color: transparent;
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    height: 50px;
    .card3 {
      margin: 0 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: #fff7ee;
      border-radius: 10px;
      .icon {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
      .content {
        flex: 1;
        .text {
          display: block;
          line-height: 1;
          text-align: left;
          color: #dfae66;
        }
      }
    }
  }
}
</style>
